import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  manageContractorHeading = new BehaviorSubject(false);
  manageServicesHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageSubCategoriesHeading= new BehaviorSubject(false);
  manageCategoriesHeading= new BehaviorSubject(false);
}
