import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';

@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;


    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg:string){
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;
    userName: '3xMF@3#';
    password: '9raPMFHOMECARE@3!)#@done';

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    
    public get basicAuthentication() {
        let data = {
            userName: this.userName,
            password: this.password
        }
        return data;
    }

    login(user: User) {
        return this.http.post<any>(this.url + 'admin-user/login', user);
    }

    reset(data: any) {
        return this.http.post(this.url + 'user/reset-password',data);
    }


    forgetPassword(data: any) {
        return this.http.post(this.url + 'admin-user/forgot-password',data);
    }


    

    logout(data:any) {
        return this.http.post(this.url + 'user/logout', data);
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.put(this.url + 'admin-user/change-password', data);
    }

    emailValidation(data:any){
        return this.http.post(this.url + "user/emailExists", data);
    }


}