import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SessionService } from "./shared/services/session.service";
import { MatSelectModule } from "@angular/material/select";
import { DashboardService } from "./dashboard/services/dashboard.service";
import { ManageDiseasesService } from "./views/manage-service/manage-service.service";
import { ManageContractorsService } from "./views/manage-contractors/manage-contractors.service";
import { ManageCategoryService } from "./views/manage-category/manage-category.service";
import { ManageSubCategoryService } from "./views/manage-sub-category/manage-sub-category.service";


@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    MatSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    DashboardService,
    SessionService,
    ManageDiseasesService,
    ManageContractorsService,
    ManageCategoryService,
    ManageSubCategoryService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
