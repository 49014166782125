import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {

  email:any;
  ngOnInit(): void {
    this.email = JSON.parse(localStorage.getItem('email'));
  }

  resend(){
    alert(this.email);
  }

}
