import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData:any;
  graphData: any;
  months: number = 6;
  currentUser:any;
  analyticsData:any;
  finalCount:any;
  clientCounts:number=0;
  careGiverCounts:number=0;
  bookingCount:number=0;
  incomeCount:number=0;

  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;

    this.dashboardService.getDashboardData().subscribe((res:any) => {
      if(res.type == 'success'){
         this.analyticsData = res.data;


         if(this.analyticsData?.clientCounts>0){
          let clientCountsStop = setInterval(()=> {
            this.clientCounts++
            if(this.clientCounts == this.analyticsData?.clientCounts){
              clearInterval(clientCountsStop);
            }
          })
         }
        

         if(this.analyticsData?.contractorsCounts>0){
          let caregiverCountsStop = setInterval(()=> {
            this.careGiverCounts++
            if(this.careGiverCounts == this.analyticsData?.contractorsCounts){
              clearInterval(caregiverCountsStop);
            }
          })
         }

         if(this.analyticsData?.jobsCount>0){
          let bookingCountsStop = setInterval(()=> {
            this.bookingCount++
            if(this.bookingCount == this.analyticsData?.jobsCount){
              clearInterval(bookingCountsStop);
            }
          })
         }


         if(this.analyticsData?.revenue>0){
          let incomeCountStop = setInterval(()=> {
            if(this.analyticsData?.revenue>100){
              this.incomeCount = this.incomeCount+100;
            }else{
              this.incomeCount++;
            }
            
            if(this.incomeCount >= this.analyticsData?.revenue){
              this.incomeCount = this.analyticsData?.revenue;
              clearInterval(incomeCountStop);
            }
          })
         }

         
      }
    })
    
  }
  

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }
}
