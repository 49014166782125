<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
            <div class="total-counts">
                <div class="total-count total-users col-lg-5 mt-3 card-border" style="cursor: unset;">
                    <img src="../../../assets/dashboard_icons/Devices.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{clientCounts}}</h2>
                        <p>Total Number of Registered Clients</p>
                    </div>
                </div>

                <div class="total-count total-careers  col-lg-5 mt-3 card-border" style="cursor: unset;">
                    <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="caregiver" class="counter">{{careGiverCounts}}</h2>
                        <p>Total Number of Registered Contractors </p>
                    </div>
                </div>


                <div class="total-count total-gs  col-lg-5 mt-3 card-border" style="cursor: unset;">
                    <img src="../../../assets/dashboard_icons/Active age group.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="booking">{{bookingCount}}</h2>
                        <p>Total Number of Jobs Posted </p>
                    </div>
                </div>

                <div class="total-count total-resources  col-lg-5 mt-3 card-border"  style="cursor: unset;">
                    <img src="../../../assets/dashboard_icons/View Feedback.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="income">{{incomeCount}}</h2>
                        <p>Total Revenue Generated </p>
                    </div>
                </div>

            </div>
        </div>


</div>