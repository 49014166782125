

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageContractorsService {

    url: any = environment.API_URL;
    constructor(
        private http: HttpClient
    ) { }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }


    getContractor(page,pageLimit){
        return this.http.get(this.url + `user/contractor-list?page=${page}&pageSize=${pageLimit}`);
    }


    updateContractor(id:any,data:any){
        return this.http.put(this.url + 'user/update-contractor/'+id,data)
    }

    getContractorById(id:any){
        return this.http.get(this.url + 'user/contractor-details/'+id)
    }

}