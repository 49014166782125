import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  manageContractorHeading: boolean = false;
  manageServicesHeading: boolean = false;
  welcomeUserMsg: boolean = false;
  dashboardHeading: boolean = false;
  changePasswordHeading: boolean = false;
  manageSubCategoriesHeading: boolean = false;
  manageCategoriesHeading: boolean = false;


  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: any = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {

    this._header.manageContractorHeading.subscribe((res) => {
      this.manageContractorHeading = res;
    });


    this._header.manageServicesHeading.subscribe((res) => {
      this.manageServicesHeading = res;
    });


    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.manageSubCategoriesHeading.subscribe((res) => {
      this.manageSubCategoriesHeading = res;
    });

    this._header.manageCategoriesHeading.subscribe((res) => {
      this.manageCategoriesHeading = res;
    });

  }

  userName: any;
  firstName: any;
  lastName: any;
  allDate: any;


  currentUser: any;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();

  }

  logout() {
    this.modalRef.hide();

    this.authService.logout({ email: this.currentUser.email, password: this.currentUser.password }).subscribe((res: any) => {
      console.log(res.status);
      if (res.status == 200) {
        localStorage.removeItem("currentUser");
      }
    });
    this.router.navigateByUrl("/auth/login");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  confirm() {
    this.logout();
  }

  cancel() {
    this.modalRef.hide();
  }
}
