import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageSubCategoryService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data:any){
        return this.http.post(this.url + 'file/upload', data);
    }

    addCategory(data:any){
        return this.http.post(this.url + 'category/create-sub-category',data);
    }

    categoryList(){
        return this.http.get(this.url + 'category/list');
    }


    getCategoryByParent(parent:any){
        return this.http.get(this.url + 'category/list?page=1?&limit=1000&parentCategory='+parent);
    }

    subCategoryList(categoryId){
        return this.http.get(this.url + 'category/list-subcategories?page=1&limit=1000&categoryId='+categoryId);
    }

    updateCategory(id:any,data){
        return this.http.put(this.url + 'category/update-sub-category/'+id,data);
    }


    deleteCategory(data:any){
        return this.http.post(this.url + 'category/delete',data);
    }


    deleteSubCate(id){
        return this.http.delete(this.url + 'category/delete-sub-category/'+id)
    }

    categoryListFilter(data:any){
        return this.http.get(this.url + 'category/list?page=1?&limit=1000&parentCategory='+data);
    }

    categoryListData(data:any){
        return this.http.get(this.url + 'category/list?page=1?&limit=1000&parentCategory='+data);
    }


}