import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted: boolean = false;
  error = error.err;
  loginForm:FormGroup;
  hide = true;
  hide1 = true;
  hide2 = true;
  userType: any = 1;
  check: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }



  forgetEmail:any;

  ngOnInit() {
    this.initForm();
    this.forgetEmail = JSON.parse(localStorage.getItem('email'));
    this.loginForm.get('email').patchValue(this.forgetEmail);
   }

  get f() {
    return this.loginForm.controls;
  }

  initForm(){
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
      // userType: 1,
    };
    this.authService.login(user).subscribe(
      (res: any) => {
        if(res.type == 'success'){
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.router.navigateByUrl('/analytics-dashboard');
          localStorage.removeItem('receiveEmail');
        }else{
          this.toastr.error(res.message)
        }
      },
      (err) => {
        console.log(err);
        this.toastr.error(err.error.message);
      }
    );
  }
}
