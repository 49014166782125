import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
        import("../app/dashboard/dashboard.module").then(
          (m) => m.DashboardModule
        )
      },

      {
        path: "manage/contractors",
        loadChildren: () =>
          import("./views/manage-contractors/manage-contractors.module").then(
            (m) => m.ManageContractorsModule
          ),
      },

      {
        path: "manage/service",
        loadChildren: () =>
          import("./views/manage-service/manage-service.module").then(
            (m) => m.ManageServiceModule
          ),
      },

      {
        path: "manage/category",
        loadChildren: () =>
          import("./views/manage-category/manage-category.module").then(
            (m) => m.ManageCategoryModule
          ),
      },


      {
        path: "manage/sub-category",
        loadChildren: () =>
          import("./views/manage-sub-category/manage-sub-category.module").then(
            (m) => m.ManageSubCategoryModule
          ),
      }

    ],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
