<div class="sidebar" (keydown)="disabledTab($event)">
  <div class="logo"><img src="../../../assets/Logo.svg" routerLink="/analytics-dashboard" alt="logo"></div>
  <div class="header-links">
    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/dashboard.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/dashboard.svg" alt="" height="24" width="24">
      </span>Reporting</a>


    <a routerLink="/manage/contractors" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Client.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Client.svg" alt="" height="24" width="24">
      </span>Manage Contractors</a>

    <a routerLink="/manage/category" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Services.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Services.svg" alt="" height="24" width="24">
      </span>Manage Category</a>

    <a routerLink="/manage/sub-category" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Services.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Services.svg" alt="" height="24" width="24">
      </span>Manage Sub Category</a>

    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Chnage Password Black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Chnage Password Black.svg" alt="" height="24"
          width="24">
      </span>Change Password</a>



    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Black/Log Out Black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/sidbar_icons/Black/Log Out Black.svg" alt="" height="24"
          width="24">
      </span>Logout</a>


    <ng-template #template>
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center delete-modal align-items-center">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
        <p>Are you sure you <br>want to logout</p>
        <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()">Yes</button>
        </div>
      </div>
    </ng-template>

  </div>
</div>