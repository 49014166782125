import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategoryService {

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data:any){
        return this.http.post(this.url + 'file/upload', data);
    }

    addCategory(data:any){
        return this.http.post(this.url + 'category/create',data);
    }

    categoryList(data:any){
        return this.http.get(this.url + 'category/list?page=1?&limit=1000&parentCategory='+data);
    }

    updateCategory(id:any,data:any){
        return this.http.put(this.url + 'category/update/'+id,data);
    }


    deleteCategory(data:any){
        return this.http.delete(this.url + `category/delete/${data}`);
    }


}